<template lang="pug">
basicPages(:title="$t('privacy.title')")
  h2 {{ $t('privacy.text1') }}
  p {{ $t('privacy.text2') }}
  p {{ $t('privacy.text3') }}
  p {{ $t('privacy.text4') }}
  p {{ $t('privacy.text5') }}
  p {{ $t('privacy.text6') }}
  p {{ $t('privacy.text7') }}
  h2 {{ $t('privacy.text8') }}
  p {{ $t('privacy.text9') }}
  p {{ $t('privacy.text10') }}
  h3 {{ $t('privacy.text11') }}
  p {{ $t('privacy.text12') }}
  p {{ $t('privacy.text13') }}
  p {{ $t('privacy.text14') }}
  p {{ $t('privacy.text15') }}
  p {{ $t('privacy.text16') }}
  p {{ $t('privacy.text17') }}
  h3 {{ $t('privacy.text18') }}
  p {{ $t('privacy.text19') }}
  p {{ $t('privacy.text20') }}
  p {{ $t('privacy.text21') }}
  p {{ $t('privacy.text22') }}
  p {{ $t('privacy.text23') }}
  p {{ $t('privacy.text24') }}
  h3 {{ $t('privacy.text25') }}
  p {{ $t('privacy.text26') }}
  p {{ $t('privacy.text27') }}
  h2 {{ $t('privacy.text28') }}
  p {{ $t('privacy.text29') }}
  p {{ $t('privacy.text30') }}
  p {{ $t('privacy.text31') }}
  p {{ $t('privacy.text32') }}
  p {{ $t('privacy.text33') }}
  h2 {{ $t('privacy.text34') }}
  p {{ $t('privacy.text35') }}
  p {{ $t('privacy.text36') }}
  h2 {{ $t('privacy.text37') }}
  p {{ $t('privacy.text38') }}
  p {{ $t('privacy.text39') }}
  p {{ $t('privacy.text40') }}
  p {{ $t('privacy.text41') }}
  p {{ $t('privacy.text42') }}
  p {{ $t('privacy.text43') }}
  p {{ $t('privacy.text44') }}
  h2 {{ $t('privacy.text45') }}
  p {{ $t('privacy.text46') }}
  p {{ $t('privacy.text47') }}
  h2 {{ $t('privacy.text48') }}
  p {{ $t('privacy.text49') }}
  p {{ $t('privacy.text50') }}
  p {{ $t('privacy.text51') }}
  p {{ $t('privacy.text52') }}
  p {{ $t('privacy.text53') }}
  p {{ $t('privacy.text54') }}
  p {{ $t('privacy.text55') }}
  p {{ $t('privacy.text56') }}
  p {{ $t('privacy.text57') }}
  h2 {{ $t('privacy.text58') }}
  p {{ $t('privacy.text59') }}
  p {{ $t('privacy.text60') }}
  p {{ $t('privacy.text61') }}
  p {{ $t('privacy.text62') }}
  h2 {{ $t('privacy.text63') }}
  p {{ $t('privacy.text64') }}
  h2 {{ $t('privacy.text65') }}
  p {{ $t('privacy.text66') }}
  p {{ $t('privacy.text67') }}
  p {{ $t('privacy.text68') }}
</template>

<script>
import basicPages from "@/components/structure/views/basicPages";
export default {
  name: "Privacidad",
  components: {
    basicPages,
  },
};
</script>

<style lang="scss">
</style>
